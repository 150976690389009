
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { setFontStyle } from 'src/styles/utils/typography';
import { theme } from 'styled-tools';
import { typography } from 'src/styles/type';
import Container from 'src/components/core/layout/container';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  isKiosk?: boolean,
  text: string
};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  padding: ${units(13)} 0;

  ${media.min('ms')`
    padding: ${units(25)} 0;
  `};
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  ${media.min('ms')`
    display: grid;
    grid-template-areas: '. textWrapper .';
    grid-template-columns: 1fr 10fr 1fr;
  `};

  ${media.min('md')`
    grid-template-columns: 2fr 8fr 2fr;
  `}

  ${media.min('lg')`
    grid-template-columns: 3fr 6fr 3fr;
  `}
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  ${theme('typography.styles.p')}

  color: ${color('grey400')};

  h2, h6, p {
    margin: 0 0 ${units(5)};
  }

  h2 {
    ${theme('typography.styles.h3')}

    color: ${color('black')};
  }

  h6 {
    ${setFontStyle(typography.typeSizes.smallH6)}
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    transition: opacity ${theme('animations.defaultTransition')};

    &:focus,
    &:hover {
      opacity: 0.8;
    }
  }

  ${media.min('ms')`
    grid-area: textWrapper;
  `}
`;

/**
 * `TextSection` component.
 */

const TextSection = (props: Props): ReactElement => {
  const { className, isKiosk, text } = props;

  return (
    <Section className={className}>
      <Container hasPadding={!isKiosk}>
        <Grid>
          <TextWrapper>
            <FadeInUpAnimation>
              <RawHtml>
                {text}
              </RawHtml>
            </FadeInUpAnimation>
          </TextWrapper>
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `TextSection` component.
 */

export default TextSection;
