
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { ifProp, theme } from 'styled-tools';
import { isExternalRoute } from 'src/core/utils/routes';
import { useInView } from 'react-intersection-observer';
import Container from 'src/components/core/layout/container';
import IconButton from 'src/components/core/buttons/icon-button';
import Image from 'src/components/core/image';
import ParallaxFadeInUp from 'src/components/core/animations/parallax-fade-in-up';
import React, { ReactElement } from 'react';
import Richtext from 'src/components/core/richtext';
import Type from 'src/components/core/typography';
import arrowRightIcon from 'src/assets/svg/arrow-right.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  bottomImageUrl: string,
  className?: string,
  description: string,
  hasPageTitle?: boolean,
  isKiosk?: boolean,
  label?: string | null,
  mainImageUrl: string,
  reverse?: boolean,
  sideImageUrl: string,
  title: string,
  url?: string | null,
};

/**
 * Fade-in-up style.
 */

const fadeInUpStyle = css`
  transition:
    opacity 0.6s ease-out,
    transform 0.6s ${theme('animations.easeInOutSine')};
  will-change: opacity, transform;

  ${ifProp('isVisible', css`
    opacity: 1;
    transform: none;
  `, css`
    opacity: 0;
    transform: translateY(25%);
  `)}
`;

/**
 * `Section` styled component.
 */

const Section = styled.section`
  background-color: ${color('white')};
  padding: ${units(13)} 0;
  position: relative;

  ${media.min('ms')`
    padding: ${units(25)} 0;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  padding: 0;

  ${media.min('ms')`
    padding: 0;
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div<{ reverse: boolean }>`
  display: grid;
  grid-template-areas:
    '. label mainImage mainImage   .           sideImage sideImage'
    '. label mainImage mainImage   .           .         .        '
    '. label .         .           .           .         .        '
    '. label .         bottomImage bottomImage .         .        '
    '. title title     title       title       title     .        '
    '. card  card      card        card        card      .        ';
  grid-template-columns: ${theme('grid.gutterMobile')}px 1fr 5fr 3fr 1.5fr 2fr ${theme('grid.gutterMobile')}px;
  grid-template-rows: 3fr 1fr repeat(2, 1.5fr) repeat(2, max-content);

  ${media.min('ms')`
    grid-template-areas:
      '. . label mainImage mainImage mainImage mainImage   .           . sideImage sideImage'
      '. . label mainImage mainImage mainImage mainImage   .           . .         .        '
      '. . label .         .         .         .           .           . .         .        '
      '. . title title     title     .         bottomImage bottomImage . .         .        '
      '. . title title     title     .         .           .           . .         .        '
      '. . card  card      card      .         .           .           . .         .        ';

    grid-template-columns: ${theme('grid.gutter')}px 3fr ${units(6)} 2fr 9fr 3fr 6fr repeat(2, 3fr) 6fr ${theme('grid.gutter')}px;
    grid-template-rows: 2fr 1fr repeat(2, 1fr) repeat(2, max-content);
  `}

  ${ifProp('reverse', css`
    grid-template-areas:
      'sideImage sideImage .           mainImage   mainImage label .'
      '.         .         .           mainImage   mainImage label .'
      '.         .         .           .           .         label .'
      '.         .         bottomImage bottomImage .         label .'
      '.         title     title       title       title     title .'
      '.         card      card        card        card      card  .';

    grid-template-columns: ${theme('grid.gutterMobile')}px 2fr 1.5fr 3fr 5fr 1fr ${theme('grid.gutterMobile')}px;

    ${media.min('ms')`
      grid-template-areas:
      'sideImage sideImage . .           mainImage   mainImage mainImage mainImage label . .'
      '.         .         . .           mainImage   mainImage mainImage mainImage label . .'
      '.         .         . .           .           .         .         .         label . .'
      '.         .         . bottomImage bottomImage .         title     title     .     . .'
      '.         .         . .           .           .         title     title     .     . .'
      '.         .         . .           .           .         card      card      .     . .';
      grid-template-columns: ${theme('grid.gutter')}px 6fr repeat(2, 3fr) 6fr 3fr 9fr 2fr ${units(6)} 3fr ${theme('grid.gutter')}px;
    `}
  `)};
`;

/**
 * `LabelWrapper` styled component.
 */

const LabelWrapper = styled.div<{ isVisible: boolean }>`
  grid-area: label;
  position: relative;
  white-space: nowrap;

  ${fadeInUpStyle}
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Small).attrs({ xSmall: true })<{ reverse: boolean }>`
  color: ${color('brown400')};
  font-style: italic;
  left: 0;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transform: rotate(-90deg) translate(-100%, 0);
  transform-origin: 0 0;

  ${ifProp('reverse', css`
    left: ${units(-2.5)};
    transform: rotate(-90deg) translate(-100%, ${units(4)});

    ${media.min('xs')`
      left: ${units(-1.5)};
    `}

    ${media.min('sm')`
      left: ${units(-0.5)};
    `}

    ${media.min('ms')`
      left: 0;
    `}
  `)};
`;

/**
 * `MainImageWrapper` styled component.
 */

const MainImageWrapper = styled.div<{ isVisible: boolean }>`
  grid-area: mainImage;
  grid-row: 1 / 4;
  position: relative;

  ${fadeInUpStyle}
`;

/**
 * `BottomImageWrapper` styled component.
 */

const BottomImageWrapper = styled.div`
  grid-area: bottomImage;
  grid-row: 3 / 5;
  padding-bottom: 94%;
  position: relative;
  z-index: 1;

  > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

/**
 * `SideImageWrapper` styled component.
 */

const SideImageWrapper = styled.div`
  grid-area: sideImage;
  position: relative;

  > div {
    height: 100%;
  }
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H3)<{ reverse: boolean }>`
  align-items: flex-start;
  color: ${color('black')};
  display: flex;
  grid-area: title;

  ${media.min('ms')`
    align-items: flex-end;
  `}

  ${ifProp('reverse', css`
    padding-top: ${units(4)};
  `)};
`;

/**
 * `Card` styled component.
 */

const Card = styled(ParallaxFadeInUp)`
  grid-area: card;
  padding-top: ${units(2)};

  > div {
    display: flex;
    flex-direction: column;
  }
`;

/**
 * `Description` styled component.
 */

const Description = styled(Richtext)`
  margin-bottom: ${units(3)};
`;

/**
 * `HighlightThreeImagesSection` component.
 */

const HighlightThreeImagesSection = (props: Props): ReactElement => {
  const {
    bottomImageUrl,
    className,
    description,
    hasPageTitle,
    isKiosk,
    label,
    mainImageUrl,
    reverse,
    sideImageUrl,
    title,
    url
  } = props;

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true
  });

  return (
    <Section
      className={className}
      ref={ref}
    >
      <StyledContainer hasPadding={!isKiosk}>
        <Grid reverse={reverse}>
          <LabelWrapper isVisible={inView}>
            <Label reverse={reverse}>
              {label}
            </Label>
          </LabelWrapper>

          <MainImageWrapper isVisible={inView}>
            {mainImageUrl && (
              <Image
                alt={title}
                layout={'fill'}
                objectFit={'cover'}
                src={mainImageUrl}
              />
            )}
          </MainImageWrapper>

          <BottomImageWrapper>
            {bottomImageUrl && (
              <ParallaxFadeInUp
                parallaxOptions={{
                  percentage: 0.1,
                  speed: 3
                }}
              >
                <Image
                  alt={title}
                  layout={'fill'}
                  objectFit={'cover'}
                  src={bottomImageUrl}
                />
              </ParallaxFadeInUp>
            )}
          </BottomImageWrapper>

          <SideImageWrapper>
            {sideImageUrl && (
              <ParallaxFadeInUp parallaxOptions={{ speed: 1 }}>
                <Image
                  alt={title}
                  layout={'fill'}
                  objectFit={'cover'}
                  src={sideImageUrl}
                />
              </ParallaxFadeInUp>
            )}
          </SideImageWrapper>

          <Title
            reverse={reverse}
            {...(hasPageTitle && {
              as: 'h1'
            })}
          >
            <ParallaxFadeInUp
              parallaxOptions={{
                percentage: 0.1,
                speed: 3
              }}
            >
              {title}
            </ParallaxFadeInUp>
          </Title>

          <Card
            parallaxOptions={{
              percentage: 0.3,
              speed: 3
            }}
          >
            <Description>
              {description}
            </Description>

            {url && (
              <IconButton
                aria-label={title}
                {...!isExternalRoute(url) ? {} : {
                  rel: 'noopener',
                  target: '_blank'
                }}
                href={url}
                icon={arrowRightIcon}
                iconSize={units(5)}
              />
            )}
          </Card>
        </Grid>
      </StyledContainer>
    </Section>
  );
};

/**
 * Export `HighlightThreeImagesSection` component.
 */

export default HighlightThreeImagesSection;
