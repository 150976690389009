
/**
 * Module depenencies.
 */

import { color, media, units } from 'src/styles/utils';
import { detectMobile } from 'src/core/utils/devices';
import { isExternalRoute } from 'src/core/utils/routes';
import { truncateString } from 'src/core/utils/strings';
import Container from 'src/components/core/layout/container';
import GalleryNavigation from 'src/components/core/slider/gallery-navigation';
import IconButton from 'src/components/core/buttons/icon-button';
import Image from 'src/components/core/image';
import Label from 'src/components/core/typography/label';
import Lightbox from 'src/components/lightbox';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import RouterLink from 'src/components/core/links/router-link';
import Slider, { Slide } from 'src/components/core/slider';
import Type from 'src/components/core/typography';
import VideoPlayer, { PlayVideoButton } from 'src/components/core/video-player';
import arrowRightIcon from 'src/assets/svg/arrow-right.svg';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import styled from 'styled-components';

/**
 * `Banner` type.
 */

type Banner = {
  fileUrl: string,
  id: string,
  linkUrl?: string,
  subtitle?: string,
  title?: string,
  videoUrl?: string
};

/**
 * `Props` type.
 */

type Props = {
  banners: Banner[],
  className?: string,
  hasPageTitle?: boolean
  isKiosk?: boolean
};

/**
 * `Section` component.
 */

const Section = styled.section`
  display: grid;
  grid-template-areas:
    'gallery'
    'container';
  grid-template-columns: 100%;
  grid-template-rows: 1fr ${units(3)} minmax(${units(9.5)}, auto) max-content ${units(8)};
  position: relative;

  ${media.max('ms')`
    max-height: calc(100vh - ${units(1)});
  `}

  ${media.min('ms')`
    grid-template-areas:
      'gallery   gallery    gallery'
      'container navigation .';
    grid-template-columns: 9fr 2fr 1fr;
    grid-template-rows: 6fr minmax(${units(9.5)}, max-content) ${units(3)} ${units(5)} max-content ${units(1)};
  `}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Container)`
  grid-area: container;
  grid-row: 2 / span 3;

  ${media.min('ms')`
    grid-row: 2 / span 4;
    padding-right: 0;
  `}
`;

/**
 * `Gallery` styled component.
 */

const Gallery = styled.div`
  grid-area: gallery;
  grid-row: 1 / span 3;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${media.min('ms')`
    grid-row: 1 / span 2;
    max-height: 70vh;
  `}
`;

/**
 * `GalleryNavigationWrapper` styled component.
 */

const GalleryNavigationWrapper = styled.div`
  align-self: flex-start;
  grid-area: navigation;
  grid-row: 3 / span 1;
  justify-self: flex-end;
  padding: ${units(1)} ${units(5)} 0 0;
  z-index: 2;

  ${media.min('ms')`
    align-self: center;
    grid-row: 4 / span 1;
    padding: 0;
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  position: relative;
  z-index: 1;

  ${media.min('ms')`
    display: grid;
    grid-template-areas: '. slideCard';
    grid-template-columns: 1fr 11fr;
  `}
`;

/**
 * `SlideCard` styled component.
 */

const SlideCard = styled.div`
  background-color: ${color('white')};
  display: grid;
  grid-area: slideCard;
  grid-template-areas: '. title linkArrow .';
  grid-template-columns: 1fr 7fr 2fr 1fr;
  grid-template-rows: repeat(2, max-content);
  padding-top: ${units(3)};

  ${media.min('ms')`
    grid-template-areas:
      'title . .'
      'title . linkArrow';
    grid-template-columns: 10fr repeat(2, 1fr);
    grid-template-rows: max-content minmax(12vw, auto);
    padding: ${units(3)} ${units(5)} 0 ${units(5)};
  `}
`;

/**
 * `Subtitle` styled component.
 */

const Subtitle = styled(Label).attrs({ size: 'small' })`
  color: ${color('brown400')};
  font-style: italic;
  margin-bottom: ${units(3)};

  ${media.max('ms')`
    line-height: ${units(2.5)};
  `}

  ${media.min('ms')`
    margin-bottom: ${units(2)};
  `}
`;

/**
 * `LinkArrow` styled component.
 */

const LinkArrow = styled(RouterLink)`
  grid-area: linkArrow;
  justify-self: flex-end;

  ${media.min('md')`
    padding-top: ${units(1)};
  `}

  ${media.min('lg')`
    padding-top: ${units(2.5)};
  `}
`;

/**
 * `SlideImage` styled component.
 */

const SlideImage = styled.div`
  cursor: pointer;
  height: 100vh;
  position: relative;

  ${media.min('ms')`
    height: auto;
    padding-bottom: 56%;
  `}
`;

/**
 * `StyledVideoPlayer` styled component.
 */

const StyledVideoPlayer = styled(VideoPlayer)`
  height: 100vh;
  pointer-events: none;
  position: relative;

  ${media.min('ms')`
    height: auto;
  `}
`;

/**
 * `BannerSection` component.
 */

const BannerSection = (props: Props): ReactElement | null => {
  const { banners, className, hasPageTitle, isKiosk } = props;
  const normalizedBanners = useMemo(() => {
    return filter(banners, ({ fileUrl, videoUrl }) => !isEmpty(fileUrl) || !isEmpty(videoUrl));
  }, [banners]);

  const total = size(normalizedBanners);
  const sliderRef = useRef<any>();
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [isOpenLightbox, setOpenLightbox] = useState<boolean>();
  const [isMobile, setIsMobile] = useState<boolean>();
  const handleSlideChange = useCallback(({ activeIndex }) => {
    setActiveSlide(activeIndex);
  }, []);

  const handleClickDot = useCallback((index: number) => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.swiper.slideTo(index);
    }
  }, []);

  const handleClickSlide = useCallback(() => {
    setOpenLightbox(true);
  }, []);

  useEffect(() => {
    if (sliderRef && sliderRef.current && isOpenLightbox) {
      sliderRef.current.swiper.autoplay.stop();

      return;
    }

    if (sliderRef && sliderRef.current && !isOpenLightbox) {
      sliderRef.current.swiper.autoplay.start();

      return;
    }
  }, [isOpenLightbox]);

  useEffect(() => {
    setIsMobile(detectMobile());
  }, []);

  if (isEmpty(normalizedBanners)) {
    return null;
  }

  return (
    <>
      <Section className={className}>
        <Gallery>
          <Slider
            autoplay={{
              delay: 6000,
              disableOnInteraction: true
            }}
            effect={'fade'}
            onClick={handleClickSlide}
            onSlideChange={handleSlideChange}
            ref={sliderRef}
            speed={750}
          >
            {map(normalizedBanners, ({ fileUrl, id, title, videoUrl }: Banner, index: number) => (
              <Slide key={id}>
                {videoUrl && !isMobile ? (
                  <StyledVideoPlayer
                    controls={false}
                    loop
                    muted
                    playing={index === activeSlide}
                    url={videoUrl}
                  />
                ) : (
                  <SlideImage>
                    <Image
                      alt={title}
                      layout={'fill'}
                      objectFit={'cover'}
                      src={fileUrl}
                    />

                    {videoUrl && isMobile && <PlayVideoButton />}
                  </SlideImage>
                )}
              </Slide>
            ))}
          </Slider>
        </Gallery>

        <GalleryNavigationWrapper>
          {total > 1 && (
            <GalleryNavigation
              current={activeSlide}
              displayPagination
              onGoToNext={() => handleClickDot(activeSlide + 1)}
              onGoToPrevious={() => handleClickDot(activeSlide - 1)}
              total={total}
            />
          )}
        </GalleryNavigationWrapper>

        {normalizedBanners[activeSlide]?.title && (
          <Wrapper hasPadding={!isKiosk}>
            <Grid>
              <SlideCard>
                <Type.H2
                  {...(hasPageTitle && {
                    as: 'h1'
                  })}
                  gridArea={'title'}
                >
                  {normalizedBanners[activeSlide]?.subtitle && (
                    <Subtitle>
                      {`${normalizedBanners[activeSlide]?.subtitle} `}
                    </Subtitle>
                  )}

                  {truncateString(normalizedBanners[activeSlide]?.title, 27)}
                </Type.H2>

                {normalizedBanners[activeSlide]?.linkUrl && (
                  <LinkArrow
                    {...!isExternalRoute(normalizedBanners[activeSlide]?.linkUrl) ? {} : {
                      rel: 'noopener',
                      target: '_blank'
                    }}
                    gridArea={'linkArrow'}
                    href={normalizedBanners[activeSlide]?.linkUrl}
                  >
                    <IconButton
                      aria-label={'Go to'}
                      icon={arrowRightIcon}
                      iconSize={units(5)}
                    />
                  </LinkArrow>
                )}
              </SlideCard>
            </Grid>
          </Wrapper>
        )}
      </Section>

      {total > 0 && (
        <Lightbox
          initialSlide={activeSlide}
          isOpen={isOpenLightbox}
          items={map(banners, ({ fileUrl, title, videoUrl }) => ({
            ...videoUrl ? {
              coverImage: fileUrl,
              type: 'video',
              url: videoUrl
            } : {
              type: 'image',
              url: fileUrl
            },
            label: title
          }))}
          onClose={() => setOpenLightbox(false)}
        />
      )}

    </>
  );
};

/**
 * Export `BannerSection` component.
 */

export default BannerSection;
