
/**
 * Module dependencies.
 */

import { ContactProps } from 'src/types/contacts';
import { color, media, units } from 'src/styles/utils';
import { colors } from 'src/styles/colors';
import { getModuleTheme } from 'src/core/utils/colors';
import { isExternalRoute } from 'src/core/utils/routes';
import { normalizeNewLine } from 'src/core/utils/strings';
import { prop, theme } from 'styled-tools';
import { useTranslate } from 'src/core/utils/translator';
import Box from 'src/components/core/layout/box';
import Button from 'src/components/core/buttons/button';
import Container from 'src/components/core/layout/container';
import Image from 'src/components/core/image';
import ParallaxFadeInUp from 'src/components/core/animations/parallax-fade-in-up';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement } from 'react';
import Type from 'src/components/core/typography';
import map from 'lodash/map';
import styled from 'styled-components';
import useContacts from 'src/api/app/contacts/use-contacts';

/**
 * `Props` type.
 */

type Props = {
  bgColor?: string | null,
  buttonLabel?: string | null,
  buttonUrl?: string | null,
  className?: string,
  imageUrl: string | null,
  isKiosk?: boolean,
  label?: string | null,
  title: string,
};

/**
 * Colors theme config.
 */

const colorsThemeConfig = {
  dark: {
    labelColor: colors.grey400,
    textColor: colors.grey400,
    titleColor: colors.black
  },
  light: {
    labelColor: colors.beige200,
    textColor: colors.white,
    titleColor: colors.white
  }
};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  display: grid;
  grid-template-areas:
    'bgColor'
    'imageContainer'
    'detailsContainer';
  grid-template-columns: 100%;
  grid-template-rows: repeat(2, 1fr) max-content ${units(3.5)};
  padding: ${units(13)} 0;

  ${media.min('ms')`
    padding: ${units(25)} 0;
  `}

  ${media.min('md')`
    grid-template-areas:
      'bgColor'
      'imageContainer'
      'detailsContainer';
    grid-template-rows: repeat(2, 150px) max-content repeat(2, ${units(3.5)}) max-content ${units(25)};
    padding: 0;
  `}
`;

/**
 * `Background` styled component.
 */

const Background = styled.div<{ bgColor?: string | null }>`
  background-color: ${prop('bgColor', color('grey100'))};
  grid-area: bgColor;
  grid-row: 2 / 4;

  ${media.min('md')`
    grid-row: 1 / 4;
  `}
`;

/**
 * `ImageContainer` styled component.
 */

const ImageContainer = styled(Container)`
  grid-area: imageContainer;
  grid-row: 1 / span 2;

  ${media.min('md')`
    grid-row: 3 / 7;
  `}
`;

/**
 * `ImageGridWrapper` styled component.
 */

const ImageGridWrapper = styled.div<{ reverse?: boolean }>`
  ${media.min('ms')`
    display: grid;
    grid-template-areas: '. imageContentWrapper .';
    grid-template-columns: 1fr 10fr 1fr;
  `}

  ${media.min('md')`
    grid-template-columns: 7fr 4fr 1fr;
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  padding-bottom: 100%;
  position: relative;

  > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${media.max('md')`
    margin-bottom: ${units(3)};
  `}
`;

/**
 * `DetailsContainer` styled component.
 */

const DetailsContainer = styled(Container)`
  grid-area: detailsContainer;
  grid-row: 3 / span 2;

  ${media.min('md')`
    grid-row: 2 / span 3;
  `}
`;

/**
 * `DetailsGridWrapper` styled component.
 */

const DetailsGridWrapper = styled.div<{ reverse?: boolean }>`
  ${media.min('ms')`
    display: grid;
    grid-template-areas: '. detailsContentWrapper .';
    grid-template-columns: 1fr 10fr 1fr;
  `}

  ${media.min('md')`
    grid-template-columns: 1fr 5fr 6fr;
  `}
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Paragraph)<{ colorTheme: string }>`
  color: ${prop('colorTheme')};
  font-style: italic;
  margin-bottom: ${units(2)};
  text-transform: uppercase;

  ${media.max('md')`
    margin-bottom: ${units(3)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H2)<{ colorTheme: string }>`
  color: ${prop('colorTheme')};
  margin-bottom: ${units(3)};

  ${media.min('md')`
    margin-bottom: ${units(5)};
  `}
`;

/**
 * `AddressWrapper` styled component.
 */

const AddressWrapper = styled.div<{ colorTheme: string }>`
  color: ${prop('colorTheme')};
  min-height: 150px;

  ${media.min('ms')`
    display: flex;
  `}

  ${media.min('md')`
    margin-bottom: ${units(8)};
  `}
`;

/**
 * `Address` styled component.
 */

const Address = styled.div`
  ${media.max('md')`
    margin-bottom: ${units(5)};
  `}

  ${media.min('ms')`
    flex: 0 0 50%;
    padding-right: ${units(2)};
  `}
`;

/**
 * `AddressName` styled component.
 */

const AddressName = styled(Type.H6).attrs({ xSmall: true })`
  margin-bottom: ${units(2)};
`;

/**
 * `AddressInfo` styled component.
 */

const AddressInfo = styled(Type.Paragraph)`
  margin-bottom: ${units(2)};
`;

/**
 * `MapLink` styled component.
 */

const MapLink = styled.a<{ colorTheme: string }>`
  border-bottom: 1px solid ${prop('colorTheme')};
  color: ${prop('colorTheme')};
  font-size: ${units(2)};
  font-weight: 400;
  line-height: ${units(3)};
  text-decoration: none;
  text-transform: uppercase;
  transition: ${theme('animations.defaultTransition')};
  transition-property: border-bottom-color, color;

  &:focus,
  &:hover {
    border-bottom-color: ${({ colorTheme }) => color.transparentize(colorTheme, 0.8)};
    color: ${({ colorTheme }) => color.transparentize(colorTheme, 0.8)};
  }
`;

/**
 * `ContactsSection` component.
 */

const ContactsSection = (props: Props): ReactElement => {
  const {
    bgColor,
    buttonLabel,
    buttonUrl,
    className,
    imageUrl,
    isKiosk,
    label,
    title
  } = props;

  const { translate } = useTranslate();
  const moduleTheme = getModuleTheme(bgColor) ?? 'dark';
  const {
    labelColor,
    textColor,
    titleColor
  } = colorsThemeConfig[moduleTheme];

  const { data, isSuccess } = useContacts<ContactProps[]>();

  return (
    <Section className={className}>
      <Background bgColor={bgColor} />

      <DetailsContainer hasPadding={!isKiosk}>
        <DetailsGridWrapper>
          <Box
            gridArea={'detailsContentWrapper'}
            position={'relative'}
            zIndex={1}
          >
            {label && (
              <Label colorTheme={labelColor}>
                <ParallaxFadeInUp parallaxOptions={{ speed: 3 }}>
                  {label}
                </ParallaxFadeInUp>
              </Label>
            )}

            <Title colorTheme={titleColor}>
              <ParallaxFadeInUp parallaxOptions={{ speed: 2 }}>
                {title}
              </ParallaxFadeInUp>
            </Title>

            {isSuccess && (
              <ParallaxFadeInUp parallaxOptions={{ speed: 1 }}>
                <AddressWrapper colorTheme={textColor}>
                  {map(data, ({ description, mapUrl, name }, index: number) => (
                    <Address key={index}>
                      <AddressName>
                        {name}
                      </AddressName>

                      <RawHtml element={AddressInfo}>
                        {normalizeNewLine(description)}
                      </RawHtml>

                      {!isKiosk && mapUrl && (
                        <MapLink
                          colorTheme={labelColor}
                          href={mapUrl}
                          rel={'noopener'}
                          target={'_blank'}
                        >
                          {translate('footer:viewMap')}
                        </MapLink>
                      )}
                    </Address>
                  ))}
                </AddressWrapper>
              </ParallaxFadeInUp>
            )}

            {buttonLabel && buttonUrl && (
              <Button
                {...!isExternalRoute(buttonUrl) ? {} : {
                  rel: 'noopener',
                  target: '_blank'
                }}
                colorTheme={'primary'}
                href={buttonUrl}
                variant={'fill'}
              >
                {buttonLabel}
              </Button>
            )}
          </Box>
        </DetailsGridWrapper>
      </DetailsContainer>

      <ImageContainer>
        <ImageGridWrapper>
          <Box
            gridArea={'imageContentWrapper'}
            position={'relative'}
            zIndex={1}
          >
            <ImageWrapper>
              {imageUrl && (
                <ParallaxFadeInUp parallaxOptions={{ speed: 2 }}>
                  <Image
                    alt={title}
                    layout={'fill'}
                    objectFit={'cover'}
                    src={imageUrl}
                  />
                </ParallaxFadeInUp>
              )}
            </ImageWrapper>
          </Box>
        </ImageGridWrapper>
      </ImageContainer>
    </Section>
  );
};

/**
 * Export `ContactsSection` component.
 */

export default ContactsSection;
