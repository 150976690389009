
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { ifProp, theme } from 'styled-tools';
import { normalizeNewLine } from 'src/core/utils/strings';
import Container from 'src/components/core/layout/container';
import Image from 'src/components/core/image';
import ParallaxFadeInUp from 'src/components/core/animations/parallax-fade-in-up';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement } from 'react';
import Richtext from 'src/components/core/richtext';
import Type from 'src/components/core/typography';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  description: string,
  hasPageTitle?: boolean,
  imageBigUrl: string,
  imageSmallUrl: string,
  isKiosk?: boolean,
  lead?: string | null,
  reverse?: boolean,
  title: string,
  titleTag?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | null
}

/**
 * `Section` styled component.
 */

const Section = styled.section`
  padding: ${units(6)} 0;

  ${media.min('md')`
    padding: ${units(12)} 0;
  `};
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div<{ reverse: boolean }>`
  ${media.min('ms')`
    display: grid;
    grid-template-areas: '. textWrapper . imagesWrapper .';
    grid-template-columns: 1fr 4fr 1fr 5fr 1fr;

    ${ifProp('reverse', css`
      grid-template-areas: '. imagesWrapper . textWrapper .';
      grid-template-columns: 1fr 5fr 1fr 4fr 1fr;
    `)}
  `};
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  grid-area: textWrapper;

  ${media.max('md')`
    margin-bottom: ${units(6)};
  `};
`;

/**
 * `ImagesWrapper` styled component.
 */

const ImagesWrapper = styled.div`
  grid-area: imagesWrapper;
`;

/**
 * `ImagesGrid` styled component.
 */

const ImagesGrid = styled.div<{ reverse: boolean }>`
  display: grid;
  grid-template-columns: 2fr 4fr 4fr;

  ${ifProp('reverse', css`
    grid-template-columns: 4fr 4fr 2fr;
  `)}
`;

/**
 * `ImageBigWrapper` styled component.
 */

const ImageBigWrapper = styled.div<{ reverse: boolean }>`
  grid-column: 2 / 4;
  grid-row: 1 / 6;
  padding-bottom: 100%;
  position: relative;

  > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${ifProp('reverse', css`
    grid-column: 1 / 3;
  `)}
`;

/**
 * `ImageSmallWrapper` styled component.
 */

const ImageSmallWrapper = styled.div<{ reverse: boolean }>`
  grid-column: 1 / 3;
  grid-row: 4 / 8;

  ${ifProp('reverse', css`
    grid-column: 2 / 4;
  `)}
`;

/**
 * `ImageSmall` styled component.
 */

const ImageSmall = styled.div`
  padding-bottom: 100%;
  position: relative;

  > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H4)`
  color: ${color('black')};
  margin-bottom: ${units(5)};

  ${media.min('md')`
    margin-bottom: ${units(3)};
  `};
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Type.H6).attrs({ as: 'p', small: true })`
  color: ${color('grey400')};
  margin-bottom: ${units(5)};
`;

/**
 * `Description` styled component.
 */

const Description = styled(Richtext)`
  h2 {
    ${theme('typography.styles.h4')}
  }

  h3 {
    ${theme('typography.styles.h5')}
  }
`;

/**
 * `HighlightTwoImagesSection` component.
 */

const HighlightTwoImagesSection = (props: Props): ReactElement => {
  const {
    className,
    description,
    hasPageTitle,
    imageBigUrl,
    imageSmallUrl,
    isKiosk,
    lead,
    reverse,
    title,
    titleTag
  } = props;

  const asTitleTag = hasPageTitle ? 'h1' : titleTag;

  return (
    <Section className={className}>
      <Container hasPadding={!isKiosk}>
        <Grid reverse={reverse}>
          <TextWrapper>
            <ParallaxFadeInUp parallaxOptions={{ speed: 1 }}>
              <Title
                {...(asTitleTag && {
                  as: asTitleTag
                })}
              >
                {title}
              </Title>
            </ParallaxFadeInUp>

            <ParallaxFadeInUp parallaxOptions={{ speed: 2 }}>
              {lead && (
                <RawHtml element={Lead}>
                  {normalizeNewLine(lead)}
                </RawHtml>
              )}

              <Description>
                {description}
              </Description>
            </ParallaxFadeInUp>
          </TextWrapper>

          <ImagesWrapper>
            <ImagesGrid reverse={reverse}>
              <ImageBigWrapper reverse={reverse}>
                {imageBigUrl && (
                  <ParallaxFadeInUp parallaxOptions={{ percentage: 0.1 }}>
                    <Image
                      alt={title}
                      layout={'fill'}
                      objectFit={'cover'}
                      src={imageBigUrl}
                    />
                  </ParallaxFadeInUp>
                )}
              </ImageBigWrapper>

              <ImageSmallWrapper reverse={reverse}>
                <ImageSmall>
                  {imageSmallUrl && (
                    <ParallaxFadeInUp parallaxOptions={{ speed: 3 }}>
                      <Image
                        alt={title}
                        layout={'fill'}
                        objectFit={'cover'}
                        src={imageSmallUrl}
                      />
                    </ParallaxFadeInUp>
                  )}
                </ImageSmall>
              </ImageSmallWrapper>
            </ImagesGrid>
          </ImagesWrapper>
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `HighlightTwoImagesSection` component.
 */

export default HighlightTwoImagesSection;
