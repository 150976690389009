
/**
 * Module dependencies.
 */

import { QueryObserverBaseResult, useQuery } from 'react-query';
import { fetchContacts } from './fetch-contacts';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * `Props` type.
 */

type Props<P> = QueryObserverBaseResult<P, unknown>;

/**
 * `Options` type.
 */

type Options<P> = {
  initialData?: P | null
};

/**
 * Action type.
 */

const actionType = ({ locale }): string => {
  return `${locale}-contacts`;
};

/**
 * `useContacts` hook.
 */

function useContacts<P>(options?: Options<P>): Props<P> {
  const { locale } = useRouter();
  const variables = useMemo(() => ({ locale }), [locale]);
  const result = useQuery([actionType(variables), variables], () => {
    return fetchContacts(variables);
  }, {
    initialData: options?.initialData
  });

  return result;
}

/**
 * Export `useContacts` hook.
 */

export default useContacts;
