
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { colors } from 'src/styles/colors';
import { getModuleTheme } from 'src/core/utils/colors';
import { ifProp, prop } from 'styled-tools';
import { isExternalRoute } from 'src/core/utils/routes';
import { normalizeNewLine } from 'src/core/utils/strings';
import Box from 'src/components/core/layout/box';
import Button from 'src/components/core/buttons/button';
import Container from 'src/components/core/layout/container';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import Image from 'src/components/core/image';
import ParallaxFadeInUp from 'src/components/core/animations/parallax-fade-in-up';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement } from 'react';
import Type from 'src/components/core/typography';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  bgColor?: string | null,
  buttonLabel?: string | null,
  buttonUrl?: string | null,
  className?: string,
  hasPageTitle?: boolean,
  imageUrl: string,
  isKiosk?: boolean,
  label?: string | null,
  lead?: string | null,
  reverse?: boolean,
  title: string,
};

/**
 * Colors theme config.
 */

const colorsThemeConfig = {
  dark: {
    labelColor: colors.brown400,
    textColor: colors.grey400,
    titleColor: colors.black
  },
  light: {
    labelColor: colors.beige200,
    textColor: colors.white,
    titleColor: colors.white
  }
};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  display: grid;
  grid-template-areas:
    'bgColor'
    'imageContainer'
    'detailsContainer';
  grid-template-columns: 100%;
  grid-template-rows: repeat(2, 1fr) max-content ${units(3.5)};
  padding: ${units(13)} 0;
  position: relative;

  ${media.min('ms')`
    padding: ${units(25)} 0;
  `}

  ${media.min('md')`
    grid-column-gap: ${units(2)};
    grid-template-areas:
      'bgColor  '
      'detailsContainer'
      'imageContainer';
    grid-template-rows: repeat(2, ${units(12)}) max-content repeat(2, ${units(3.5)}) max-content ${units(25)};
    padding: 0;
  `}
`;

/**
 * `Background` styled component.
 */

const Background = styled.div<{ bgColor?: string | null }>`
  background-color: ${prop('bgColor', color('grey100'))};
  grid-area: bgColor;
  grid-row: 2 / 4;

  ${media.min('md')`
    grid-row: 1 / span 4;
  `}
`;

/**
 * `ImageContainer` styled component.
 */

const ImageContainer = styled(Container)`
  align-self: end;
  grid-area: imageContainer;
  grid-row: 1 / span 2;

  ${media.min('md')`
    grid-row: 2 / 7;
  `}
`;

/**
 * `ImageGridWrapper` styled component.
 */

const ImageGridWrapper = styled.div<{ reverse?: boolean }>`
  ${media.min('ms')`
    display: grid;
    grid-template-areas: '. imageContentWrapper .';
    grid-template-columns: 1fr 10fr 1fr;
  `}

  ${media.min('md')`
    grid-template-columns: 5fr 6fr 1fr;
  `}

  ${ifProp('reverse', css`
    ${media.min('md')`
      grid-template-columns: 1fr 6fr 5fr;
    `}
  `)}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  padding-bottom: 100%;
  position: relative;

  > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${media.max('md')`
    margin-bottom: ${units(3)};
  `}
`;

/**
 * `DetailsContainer` styled component.
 */

const DetailsContainer = styled(Container)`
  grid-area: detailsContainer;
  grid-row: 3 / span 2;

  ${media.min('md')`
    grid-row: 3 / span 3;
  `}
`;

/**
 * `DetailsGridWrapper` styled component.
 */

const DetailsGridWrapper = styled.div<{ reverse?: boolean }>`
  ${media.min('ms')`
    display: grid;
    grid-template-areas: '. detailsContentWrapper .';
    grid-template-columns: 1fr 10fr 1fr;
  `}

  ${media.min('md')`
    grid-template-columns: 1fr 3fr 8fr;
  `}

  ${ifProp('reverse', css`
    ${media.min('md')`
      grid-template-columns: 8fr 3fr 1fr;
    `}
  `)}
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Paragraph)<{ colorTheme: string }>`
  color: ${prop('colorTheme')};
  font-style: italic;
  margin-bottom: ${units(2)};
  text-transform: uppercase;

  ${media.max('md')`
    margin-bottom: ${units(3)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H4)<{ colorTheme: string }>`
  color: ${prop('colorTheme')};
  margin-bottom: ${units(3)};

  ${media.min('md')`
    margin-bottom: ${units(2)};
  `}
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Type.H6).attrs({
  as: 'p',
  small: true
})<{ colorTheme: string }>`
  color: ${prop('colorTheme')};
  margin-bottom: ${units(6)};
`;

/**
 * `TextImageSubtitleSection` component.
 */

const TextImageSubtitleSection = (props: Props): ReactElement => {
  const {
    bgColor,
    buttonLabel,
    buttonUrl,
    className,
    hasPageTitle,
    imageUrl,
    isKiosk,
    label,
    lead,
    reverse,
    title
  } = props;

  const moduleTheme = getModuleTheme(bgColor) ?? 'dark';
  const {
    labelColor,
    textColor,
    titleColor
  } = colorsThemeConfig[moduleTheme];

  return (
    <Section className={className}>
      <Background bgColor={bgColor} />

      <DetailsContainer hasPadding={!isKiosk}>
        <DetailsGridWrapper reverse={reverse}>
          <Box
            gridArea={'detailsContentWrapper'}
            position={'relative'}
            zIndex={1}
          >
            <ParallaxFadeInUp parallaxOptions={{ speed: 2 }}>
              {label && (
                <Label colorTheme={labelColor}>
                  {label}
                </Label>
              )}

              <Title
                colorTheme={titleColor}
                {...(hasPageTitle && {
                  as: 'h1'
                })}
              >
                {title}
              </Title>

              {lead && (
                <Lead colorTheme={textColor}>
                  <RawHtml>
                    {normalizeNewLine(lead)}
                  </RawHtml>
                </Lead>
              )}
            </ParallaxFadeInUp>

            {buttonLabel && buttonUrl && (
              <FadeInUpAnimation>
                <Button
                  aria-label={buttonLabel}
                  {...!isExternalRoute(buttonUrl) ? {} : {
                    rel: 'noopener',
                    target: '_blank'
                  }}
                  colorTheme={'primary'}
                  href={buttonUrl}
                  variant={'fill'}
                >
                  {buttonLabel}
                </Button>
              </FadeInUpAnimation>
            )}
          </Box>
        </DetailsGridWrapper>
      </DetailsContainer>

      <ImageContainer>
        <ImageGridWrapper reverse={reverse}>
          <Box
            gridArea={'imageContentWrapper'}
            position={'relative'}
            zIndex={1}
          >
            <ImageWrapper>
              {imageUrl && (
                <FadeInUpAnimation>
                  <Image
                    alt={title}
                    layout={'fill'}
                    objectFit={'cover'}
                    src={imageUrl}
                  />
                </FadeInUpAnimation>
              )}
            </ImageWrapper>
          </Box>
        </ImageGridWrapper>
      </ImageContainer>
    </Section>
  );
};

/**
 * Export `TextImageSubtitleSection` component.
 */

export default TextImageSubtitleSection;
