
/**
 * Module dependencies.
 */

import { Link } from 'src/components/core/contacts/links';
import { ShowroomModuleProps, ShowroomProps } from 'src/types/showrooms';
import { color, media, units } from 'src/styles/utils';
import { ifProp, theme } from 'styled-tools';
import { isExternalRoute } from 'src/core/utils/routes';
import { normalizeNewLine } from 'src/core/utils/strings';
import { useTranslate } from 'src/core/utils/translator';
import Box from 'src/components/core/layout/box';
import Button from 'src/components/core/buttons/button';
import CardTitle from 'src/components/core/typography/card-title';
import Container from 'src/components/core/layout/container';
import Image from 'src/components/core/image';
import Loading from 'src/components/core/loading';
import ParallaxFadeInUp from 'src/components/core/animations/parallax-fade-in-up';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement } from 'react';
import Type from 'src/components/core/typography';
import styled, { css } from 'styled-components';
import useShowroomModule from 'src/api/app/showrooms/use-showroom-module';
import useShowrooms from 'src/api/app/showrooms/use-showrooms';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  isKiosk?: boolean
};

/**
 * `Section` styled component.
 */

const Section = styled.section<{ isLoading: boolean }>`
  background-color: ${color('white')};
  padding: ${units(13)} 0;
  position: relative;

  ${media.min('ms')`
    padding: ${units(25)} 0;
  `}

  ${ifProp('isLoading', css`
    min-height: 100vh;
  `)}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'title       title       title       title       title      '
    'description description description description description'
    'leftImage   leftImage   .           .           .          '
    '.           .           rightImage  rightImage  rightImage '
    '.           .           .           .           .          '
    '.           bottomImage bottomImage bottomImage .          '
    '.           .           .           .           .          '
    '.           .           .           .           .          '
    'showrooms   showrooms   showrooms   showrooms   showrooms  ';
  grid-template-columns: 4fr 2fr repeat(2, 1fr) 4fr;
  grid-template-rows: repeat(2, max-content) 3fr 2.5fr 20px 2fr 1fr ${units(6)} max-content;

  ${media.min('ms')`
    grid-template-areas:
      '. title       title       title       title       title      .'
      '. description description description description description.'
      '. leftImage   leftImage   .           .           .          .'
      '. .           .           rightImage  rightImage  rightImage .'
      '. .           .           .           .           .          .'
      '. .           bottomImage bottomImage bottomImage .          .'
      '. .           .           .           .           .          .'
      '. .           .           .           .           .          .'
      '. showrooms   showrooms   showrooms   showrooms   showrooms  .';
    grid-template-columns: 1fr 4fr 2fr repeat(2, 1fr) 4fr 1fr;
  `}

  ${media.min('md')`
    grid-column-gap: ${units(2)};
    grid-template-areas:
      '. title         title       title       .          . .         .'
      '. description   description description .          . .         .'
      '. leftImage     leftImage   .           .          . showrooms .'
      '. .             .           rightImage  rightImage . showrooms .'
      '. .             .           .           .          . showrooms .'
      '. .             bottomImage bottomImage .          . showrooms .'
      '. .             .           .           .          . showrooms .';
    grid-template-columns: 1fr 2fr repeat(2, 1fr) 2fr 1fr 3fr 1fr;
    grid-template-rows: repeat(2, max-content) 3fr 3.15fr ${units(5)} 2.4fr 0.75fr;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H3)`
  color: ${color('black')};
  grid-area: title;
  margin-bottom: ${units(1)};

  ${media.min('md')`
    margin-bottom: ${units(3)};
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.H6).attrs({ as: 'p', small: true })`
  color: ${color('grey400')};
  margin-bottom: ${units(6)};
`;

/**
 * `LeftImageWrapper` styled component.
 */

const LeftImageWrapper = styled.div`
  grid-area: leftImage;
  grid-row: 3 / 5;
  overflow: hidden;
  padding-bottom: 123.5%;
  position: relative;
  width: calc(100% - 20px);

  > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${media.min('md')`
    width: calc(100% - ${units(2.5)});
  `}
`;

/**
 * `RightImageWrapper` styled component.
 */

const RightImageWrapper = styled.div`
  grid-area: rightImage;
  grid-row: 4 / 7;
  overflow: hidden;
  padding-bottom: 114.5%;
  position: relative;

  > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

/**
 * `BottomImageWrapper` styled component.
 */

const BottomImageWrapper = styled.div`
  grid-area: bottomImage;
  grid-row: 6 / 8;
  left: -25px;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  z-index: 2;

  > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

/**
 * `ShowroomsWrapper` styled component.
 */

const ShowroomsWrapper = styled.div`
  grid-area: showrooms;
`;

/**
 * `ShowroomsList` styled component.
 */

const ShowroomsList = styled.div`
  display: flex;
  flex-direction: column;

  ${media.min('ms')`
    flex-direction: row;
    justify-content: space-between;
  `}

  ${media.min('md')`
    flex-direction: column;
    justify-content: flex-start;
  `}
`;

/**
 * `ShowroomsListBlock` styled component.
 */

const ShowroomsListBlock = styled.div`
  &:not(:last-of-type) {
    margin-bottom: ${units(6)};

    ${media.min('ms')`
      margin-bottom: 0;
    `}

    ${media.min('md')`
      margin-bottom: ${units(13)};
    `}
  }
`;

/**
 * `ShowroomTitle` styled component.
 */

const ShowroomTitle = styled(CardTitle)`
  color: ${color('black')};
  margin-bottom: ${units(3)};
`;

/**
 * `ShowroomInfo` styled component.
 */

const ShowroomInfo = styled(Type.Paragraph)`
  color: ${color('grey400')};
`;

/**
 * `MapLink` styled component.
 */

const MapLink = styled.a`
  border-bottom: 1px solid ${color('brown400')};
  color: ${color('brown400')};
  display: table;
  font-size: ${units(2)};
  font-weight: 400;
  line-height: ${units(3)};
  text-decoration: none;
  text-transform: uppercase;
  transition: ${theme('animations.defaultTransition')};
  transition-property: border-bottom-color, color;

  &:focus,
  &:hover {
    border-bottom-color: ${color.transparentize('brown400', 0.8)};
    color: ${color.transparentize('brown400', 0.8)};
  }
`;

/**
 * `VirtualTourButton` styled component.
 */

const VirtualTourButton = styled(Button)`
  margin-top: ${units(3)};
`;

/**
 * `ShowroomsSection` component.
 */

const ShowroomsSection = ({ className, isKiosk }: Props): ReactElement => {
  const { locale, translate } = useTranslate();
  const {
    data: showroomModuleData,
    isLoading: showroomModuleIsLoading,
    isSuccess: showroomModuleIsSuccess
  } = useShowroomModule<ShowroomModuleProps>();

  const {
    data: showroomsData,
    isLoading: showroomsIsLoading,
    isSuccess: showroomsIsSuccess
  } = useShowrooms<ShowroomProps[]>();

  const isLoading = showroomModuleIsLoading || showroomsIsLoading;
  const isSuccess = showroomModuleIsSuccess && showroomsIsSuccess;

  return (
    <Section
      className={className}
      isLoading={isLoading}
    >
      <Loading active={isLoading} />

      {isSuccess && (
        <Container hasPadding={!isKiosk}>
          <Grid>
            <Title>
              <ParallaxFadeInUp parallaxOptions={{ speed: 2 }}>
                {showroomModuleData?.title}
              </ParallaxFadeInUp>
            </Title>

            <Box gridArea={'description'}>
              <ParallaxFadeInUp parallaxOptions={{ speed: 1 }}>
                <RawHtml element={Description}>
                  {normalizeNewLine(showroomModuleData?.description)}
                </RawHtml>
              </ParallaxFadeInUp>
            </Box>

            <>
              <LeftImageWrapper>
                <ParallaxFadeInUp parallaxOptions={{ speed: 1 }}>
                  <Image
                    alt={showroomModuleData?.title}
                    layout={'fill'}
                    objectFit={'cover'}
                    src={showroomModuleData?.media[0]?.fileUrl}
                  />
                </ParallaxFadeInUp>
              </LeftImageWrapper>

              <RightImageWrapper>
                <ParallaxFadeInUp
                  parallaxOptions={{
                    percentage: 0.1,
                    speed: 3
                  }}
                >
                  <Image
                    alt={showroomModuleData?.title}
                    layout={'fill'}
                    objectFit={'cover'}
                    src={showroomModuleData?.media[1]?.fileUrl}
                  />
                </ParallaxFadeInUp>
              </RightImageWrapper>

              <BottomImageWrapper>
                <ParallaxFadeInUp parallaxOptions={{ percentage: 0.1 }}>
                  <Image
                    alt={showroomModuleData?.title}
                    layout={'fill'}
                    objectFit={'cover'}
                    src={showroomModuleData?.media[2]?.fileUrl}
                  />
                </ParallaxFadeInUp>
              </BottomImageWrapper>
            </>

            {showroomsData && (
              <ShowroomsWrapper>
                <ParallaxFadeInUp parallaxOptions={{ speed: 3 }}>
                  <ShowroomsList>
                    {showroomsData.map(({
                      mapUrl,
                      mobile,
                      name,
                      phone,
                      text,
                      virtualTourUrl
                    }, index: number) => (
                      <ShowroomsListBlock key={index}>
                        <ShowroomTitle>
                          {name}
                        </ShowroomTitle>

                        <Box marginBottom={units(1)}>
                          <RawHtml element={ShowroomInfo}>
                            {normalizeNewLine(text)}
                          </RawHtml>

                          {phone && (isKiosk ? (
                            <><br />{phone}</>
                          ) : (
                            <ShowroomInfo>
                              <Link i18nkey={translate('common:sections.showrooms.phone', { phone })} />
                            </ShowroomInfo>
                          ))}

                          {mobile && (isKiosk ? (
                            <><br />{mobile}</>
                          ) : (
                            <ShowroomInfo>
                              <Link i18nkey={translate('common:sections.showrooms.mobile', { mobile })} />
                            </ShowroomInfo>
                          ))}
                        </Box>

                        {!isKiosk && mapUrl && (
                          <MapLink
                            href={mapUrl}
                            rel={'noopener'}
                            target={'_blank'}
                          >
                            {translate('footer:viewMap')}
                          </MapLink>
                        )}

                        {!isKiosk && virtualTourUrl && (
                          <VirtualTourButton
                            {...!isExternalRoute(virtualTourUrl) ? { locale } : {
                              rel: 'noopener',
                              target: '_blank'
                            }}
                            colorTheme={'primary'}
                            href={virtualTourUrl}
                            variant={'outlined'}
                          >
                            {translate('footer:virtualTour')}
                          </VirtualTourButton>
                        )}
                      </ShowroomsListBlock>
                    ))}
                  </ShowroomsList>
                </ParallaxFadeInUp>
              </ShowroomsWrapper>
            )}
          </Grid>
        </Container>
      )}
    </Section>
  );
};

/**
 * Export `ShowroomsSection` component.
 */

export default ShowroomsSection;
