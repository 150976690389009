
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { colors } from 'src/styles/colors';
import { getModuleTheme } from 'src/core/utils/colors';
import { isExternalRoute } from 'src/core/utils/routes';
import { prop } from 'styled-tools';
import Button from 'src/components/core/buttons/button';
import Container from 'src/components/core/layout/container';
import Image from 'src/components/core/image';
import ParallaxFadeInUp from 'src/components/core/animations/parallax-fade-in-up';
import React, { ReactElement } from 'react';
import Type from 'src/components/core/typography';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  bgColor?: string | null,
  buttonLabel?: string | null,
  buttonUrl?: string | null,
  className?: string,
  hasPageTitle?: boolean,
  imageUrl: string,
  isKiosk?: boolean,
  label?: string | null,
  title: string,
};

/**
 * Colors theme config.
 */

const colorsThemeConfig = {
  dark: {
    labelColor: colors.grey400,
    titleColor: colors.black
  },
  light: {
    labelColor: colors.beige200,
    titleColor: colors.white
  }
};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  background-color: ${color('white')};
  display: grid;
  grid-template-areas:
    'bgImage'
    'container';
  grid-template-columns: 100%;
  grid-template-rows: 2.15fr repeat(2, max-content) ${units(3.5)};
  overflow: hidden;
  padding-bottom: ${units(13)};
  position: relative;

  ${media.min('ms')`
    padding-bottom: ${units(25)};
  `}
`;

/**
 * `BackgroundImage` styled component.
 */

const BackgroundImage = styled.div`
  grid-area: bgImage;
  grid-row: 1 / span 2;
  padding-bottom: 62%;
  position: relative;

  > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${media.min('ms')`
    padding-bottom: 40%;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  grid-area: container;
  grid-row: 2 / -1;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'card'
    'button';
  grid-template-columns: 100%;
  grid-template-rows: repeat(2, 1fr) ${units(3.5)};
  height: 100%;

  ${media.min('ms')`
    grid-template-areas:
      '.     card   .'
      '.     button .';
    grid-template-columns: 1fr 10fr 1fr;
  `}

  ${media.min('md')`
    grid-template-columns: 1fr 7fr 4fr;
  `}

  ${media.min('lg')`
    grid-template-columns: 1fr 6fr 5fr;
  `}
`;

/**
 * `Card` styled component.
 */

const Card = styled.div<{ bgColor: string }>`
  background-color: ${prop('bgColor', color('beige100'))};
  grid-area: card;
  grid-row: 1 / span 2;
  padding: ${units(4)} ${units(4)} ${units(5.5)};

  ${media.min('ms')`
    padding: ${units(9)};
  `}
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Paragraph)<{ labelColor: string }>`
  color: ${prop('labelColor')};
  font-style: italic;
  margin-bottom: ${units(2)};
  text-transform: uppercase;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H3)<{ titleColor: string }>`
  color: ${prop('titleColor')};
  margin-bottom: ${units(1)};
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  align-self: flex-end;
  grid-area: button;
  grid-row: 2 / -1;
  margin-left: ${units(4)};

  ${media.min('ms')`
    margin-left: ${units(9)};
  `}
`;

/**
 * `HighlightOneImageSection` component.
 */

const HighlightOneImageSection = (props: Props): ReactElement => {
  const {
    bgColor,
    buttonLabel,
    buttonUrl,
    className,
    hasPageTitle,
    imageUrl,
    isKiosk,
    label,
    title
  } = props;

  const moduleTheme = getModuleTheme(bgColor) ?? 'dark';
  const { labelColor, titleColor } = colorsThemeConfig[moduleTheme];

  return (
    <Section className={className}>
      <BackgroundImage>
        {imageUrl && (
          <ParallaxFadeInUp parallaxOptions={{ speed: -2 }}>
            <Image
              alt={title}
              layout={'fill'}
              objectFit={'cover'}
              src={imageUrl}
            />
          </ParallaxFadeInUp>
        )}
      </BackgroundImage>

      <StyledContainer hasPadding={!isKiosk}>
        <ParallaxFadeInUp
          inViewOptions={{ threshold: 0.1 }}
          parallaxOptions={{ speed: 1 }}
        >
          <Grid>
            <Card bgColor={bgColor}>
              {label && (
                <Label labelColor={labelColor}>
                  {label}
                </Label>
              )}

              <Title
                titleColor={titleColor}
                {...(hasPageTitle && {
                  as: 'h1'
                })}
              >
                {title}
              </Title>
            </Card>

            {buttonUrl && buttonLabel && (
              <ButtonWrapper>
                <Button
                  aria-label={buttonLabel}
                  {...!isExternalRoute(buttonUrl) ? {} : {
                    rel: 'noopener',
                    target: '_blank'
                  }}
                  colorTheme={'primary'}
                  href={buttonUrl}
                  variant={'fill'}
                >
                  {buttonLabel}
                </Button>
              </ButtonWrapper>
            )}
          </Grid>
        </ParallaxFadeInUp>
      </StyledContainer>
    </Section>
  );
};

/**
 * Export `HighlightOneImageSection` component.
 */

export default HighlightOneImageSection;
