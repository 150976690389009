
/**
 * Module dependencies.
 */

import { ifNotProp, ifProp } from 'styled-tools';
import { isExternalRoute } from 'src/core/utils/routes';
import { media, units } from 'src/styles/utils';
import { normalizeNewLine } from 'src/core/utils/strings';
import { typography } from 'src/styles/type';
import Button from 'src/components/core/buttons/button';
import Container from 'src/components/core/layout/container';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import ParallaxFadeInUp from 'src/components/core/animations/parallax-fade-in-up';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement } from 'react';
import Richtext from 'src/components/core/richtext';
import Svg from 'src/components/core/svg';
import Type from 'src/components/core/typography';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

/**
 * `Item` type.
 */

type Item = {
  buttonLabel: string,
  buttonUrl?: string,
  description: string,
  icon: string,
  iconType: 'svg' | 'image',
  title: string
}

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  description?: string | null,
  hasPageTitle?: boolean,
  isKiosk?: boolean,
  items: Item[],
  title: string
}

/**
 * `Section` styled component.
 */

const Section = styled.section`
  padding: ${units(13)} 0;

  ${media.min('md')`
    padding: ${units(25)} 0;
  `};
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  ${media.min('md')`
    display: grid;
    grid-template-areas:
      '. title       .     .'
      '. description .     .'
      '. tools       tools .';
    grid-template-columns: 1fr 4fr 6fr 1fr;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H3)<{
  hasDescription: boolean,
  nrItems: number
}>`
  grid-area: title;
  grid-row: 1;
  margin-bottom: ${units(2)};

  ${media.min('md')`
    margin-bottom: ${units(3)};
  `}

  ${ifNotProp('hasDescription', css`
    margin-bottom: ${units(6)};

    ${media.min('md')`
      margin-bottom: ${units(13)};
    `}
  `)}
`;

/**
 * `DescriptionWrapper` styled component.
 */

const DescriptionWrapper = styled.div`
  grid-area: description;
  grid-row: 2;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Richtext)`
  margin-bottom: ${units(3)};

  ${media.min('md')`
    margin-bottom: ${units(6)};
  `}
`;

/**
 * `ToolsWrapper` styled component.
 */

const ToolsWrapper = styled.div<{ nrItems: number }>`
  ${media.min('md')`
    display: grid;
    grid-area: tools;
    grid-auto-rows: 1fr;
    grid-column-gap: 40px;
    grid-template-columns: repeat(2, 5fr);
    grid-row: 3;

    ${ifProp({ nrItems: 3 }, css`
      grid-template-columns: repeat(3, 3fr);
    `)}
  `}
`;

/**
 * `Tool`styled component.
 */

const ToolBox = styled.div<{ nrItems: number }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units(2)};
  padding: ${units(4)};

  &:last-of-type {
    margin-bottom: 0;
  }

  ${media.min('md')`
    margin-bottom: 0;

    ${ifProp({ nrItems: 2 }, css`
      padding: ${units(6)} 134px 50px ${units(6)};
    `)}
  `}
`;

/**
 * `IconImage` styled component.
 */

const IconImage = styled.img`
  display: inline-block;
  line-height: 0;
  margin-bottom: ${units(3)};
  max-width: ${units(15)};
  position: relative;
  width: ${units(15)};
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg).attrs({ size: units(15) })`
  margin-bottom: ${units(3)};
`;

/**
 * `ToolTitle` styled component.
 */

const ToolTitle = styled(Type.H5)`
  flex: 1 0 auto;
  font-family: ${typography.fontFamily.sansSerif};
  font-weight: 400;
  margin-bottom: ${units(3)};
`;

/**
 * `ToolDescription` styled component.
 */

const ToolDescription = styled(Type.Paragraph)`
  flex: 1 0 auto;
  margin-bottom: ${units(6)};
`;

/**
 * `ToolButton` styled component.
 */

const ToolButton = styled(Button)`
  align-self: flex-start;
`;

/**
 * `ToolsSection` component.
 */

const ToolsSection = (props: Props): ReactElement | null => {
  const { className, description, hasPageTitle, isKiosk, items, title } = props;
  const moduleType = items.length > 2 ? 'large' : 'medium';
  const TitleComponent = moduleType === 'large' ? Type.H3 : Type.H2;
  const TitleTool = moduleType === 'large' ? Type.H5 : Type.H4;

  if (isEmpty(items)) {
    return null;
  }

  return (
    <Section className={className}>
      <Container hasPadding={!isKiosk}>
        <Grid>
          <Title
            as={TitleComponent}
            hasDescription={!isKiosk && description}
            nrItems={items.length}
            {...(hasPageTitle && {
              as: 'h1'
            })}
          >
            <ParallaxFadeInUp parallaxOptions={{ speed: 2 }}>
              {title}
            </ParallaxFadeInUp>
          </Title>

          <DescriptionWrapper>
            <ParallaxFadeInUp parallaxOptions={{ speed: 1 }}>
              {!isKiosk && description && (
                <Description>
                  {description}
                </Description>
              )}
            </ParallaxFadeInUp>
          </DescriptionWrapper>

          <ToolsWrapper nrItems={items.length}>
            {items.map(({
              buttonLabel,
              buttonUrl,
              description,
              icon,
              iconType,
              title
            }, index: number) => index < 3 && (
              <FadeInUpAnimation key={index}>
                <ToolBox nrItems={items.length}>
                  {iconType === 'svg' && <Icon icon={icon} />}

                  {iconType === 'image' && (
                    <IconImage
                      alt={title}
                      height={120}
                      src={icon}
                      width={120}
                    />
                  )}

                  <ToolTitle as={TitleTool}>
                    {title}
                  </ToolTitle>

                  <RawHtml element={ToolDescription}>
                    {normalizeNewLine(description)}
                  </RawHtml>

                  {buttonUrl && buttonLabel && (
                    <ToolButton
                      aria-label={buttonLabel}
                      href={buttonUrl}
                      {...isExternalRoute(buttonUrl) && {
                        rel: 'noopener',
                        target: '_blank'
                      }}
                      colorTheme={'secondary'}
                      disabled={!buttonUrl}
                      variant={'outlined'}
                    >
                      {buttonLabel}
                    </ToolButton>
                  )}
                </ToolBox>
              </FadeInUpAnimation>
            ))}
          </ToolsWrapper>
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `ToolsSection` component.
 */

export default ToolsSection;
