
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { colors } from 'src/styles/colors';
import { getModuleTheme } from 'src/core/utils/colors';
import { ifProp, prop, theme } from 'styled-tools';
import { isExternalRoute } from 'src/core/utils/routes';
import { normalizeNewLine } from 'src/core/utils/strings';
import Button from 'src/components/core/buttons/button';
import Container from 'src/components/core/layout/container';
import Image from 'src/components/core/image';
import ParallaxFadeInUp from 'src/components/core/animations/parallax-fade-in-up';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement } from 'react';
import Richtext from 'src/components/core/richtext';
import Type from 'src/components/core/typography';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  bgColor?: string | null,
  buttonLabel?: string | null,
  buttonUrl?: string | null,
  className?: string,
  description?: string | null,
  hasPageTitle?: boolean,
  imageUrl: string,
  isKiosk?: boolean,
  lead?: string | null,
  reverse?: boolean,
  title: string,
  titleTag?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | null
}

/**
 * Colors theme config.
 */

const colorsThemeConfig = {
  dark: {
    buttonTheme: 'primary',
    textColor: colors.grey400,
    titleColor: colors.black
  },
  light: {
    buttonTheme: 'white',
    textColor: colors.white,
    titleColor: colors.white
  }
};

/**
 * `Section` styled component.
 */

const Section = styled.section<{ bgColor?: string | null }>`
  background-color: ${prop('bgColor', color('grey100'))};
  padding: ${units(13)} 0;

  ${media.min('ms')`
    padding: ${units(25)} 0;
  `};
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div<{ reverse: boolean }>`
  ${media.min('ms')`
    display: grid;
    grid-template-areas: '. textWrapper . imageWrapper .';
    grid-template-columns: 1fr 5fr 1fr 6fr 1fr;

    ${ifProp('reverse', css`
      grid-template-areas: '. imageWrapper . textWrapper .';
      grid-template-columns: 1fr 6fr 1fr 5fr 1fr;
    `)}
  `};
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div<{ colorTheme: string }>`
  color: ${prop('colorTheme')};
  grid-area: textWrapper;

  ${media.max('md')`
    margin-bottom: ${units(6)};
  `};
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-area: imageWrapper;
  height: 0;
  padding-bottom: 100%;
  position: relative;

  > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H4)<{ colorTheme: string }>`
  color: ${prop('colorTheme')};
  margin-bottom: ${units(5)};
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Type.H6).attrs({ as: 'p', small: true })`
  margin-bottom: ${units(5)};
`;

/**
 * `Description` styled component.
 */

const Description = styled(Richtext)<{ colorTheme: string }>`
  color: ${prop('colorTheme')};
  margin-bottom: ${units(5)};

  h2 {
    ${theme('typography.styles.h4')}
  }

  h3 {
    ${theme('typography.styles.h5')}
  }
`;

/**
 * `TextImageSection` component.
 */

const TextImageSection = (props: Props): ReactElement => {
  const {
    bgColor,
    buttonLabel,
    buttonUrl,
    className,
    description,
    hasPageTitle,
    imageUrl,
    isKiosk,
    lead,
    reverse,
    title,
    titleTag
  } = props;

  const moduleTheme = getModuleTheme(bgColor) ?? 'dark';
  const { buttonTheme, textColor, titleColor } = colorsThemeConfig[moduleTheme];
  const asTitleTag = hasPageTitle ? 'h1' : titleTag;

  return (
    <Section
      bgColor={bgColor}
      className={className}
    >
      <Container hasPadding={!isKiosk}>
        <Grid reverse={reverse}>
          <TextWrapper colorTheme={textColor}>
            <ParallaxFadeInUp parallaxOptions={{ speed: -1 }}>
              <Title
                colorTheme={titleColor}
                {...(asTitleTag && {
                  as: asTitleTag
                })}
              >
                {title}
              </Title>

              {lead && (
                <RawHtml element={Lead}>
                  {normalizeNewLine(lead)}
                </RawHtml>
              )}

              {description && (
                <Description colorTheme={textColor}>
                  {description}
                </Description>
              )}

              {buttonLabel && buttonUrl && (
                <Button
                  aria-label={buttonLabel}
                  {...!isExternalRoute(buttonUrl) ? {} : {
                    rel: 'noopener',
                    target: '_blank'
                  }}
                  colorTheme={buttonTheme}
                  href={buttonUrl}
                  variant={'outlined'}
                >
                  {buttonLabel}
                </Button>
              )}
            </ParallaxFadeInUp>
          </TextWrapper>

          <ImageWrapper>
            {imageUrl && (
              <ParallaxFadeInUp parallaxOptions={{ speed: 2 }}>
                <Image
                  alt={title}
                  layout={'fill'}
                  objectFit={'cover'}
                  src={imageUrl}
                />
              </ParallaxFadeInUp>
            )}
          </ImageWrapper>
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `TextImageSection` component.
 */

export default TextImageSection;
